import { useSelector, shallowEqual, useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Popup, Modal } from "semantic-ui-react";
import CloseImg from "assets/images/nav/close.svg";
import PlusImg from "assets/images/nav/plus.svg";
import ClearImg from "assets/images/nav/clear.svg";
import CheckImg from "assets/images/nav/check.svg";
import { SET_NEW_FILTERS } from "constants/actions";

const sentiments = [
  {
    id: "Very Negative",
    key: "Very Negative",
    name: "Very Negative",
    value: "Very Negative",
  },
  {
    id: "Slightly Negative",
    key: "Slightly Negative",
    name: "Slightly Negative",
    value: "Slightly Negative",
  },
  { id: "Neutral", key: "Neutral", name: "Neutral", value: "Neutral" },
  {
    id: "Slightly Positive",
    key: "Slightly Positive",
    name: "Slightly Positive",
    value: "Slightly Positive",
  },
  {
    id: "Very Positive",
    key: "Very Positive",
    name: "Very Positive",
    value: "Very Positive",
  },
];

const actionOrientations = [
  {
    id: "Action Oriented",
    key: "Action Oriented",
    name: "Action Oriented",
    value: "action_oriented",
  },
  {
    id: "Not Action Oriented",
    key: "Not Action Oriented",
    name: "Not Action Oriented",
    value: "not_action_oriented",
  },
];

const Filters = ({ outcomes, onSentimentFilter, onSelectOutcomes }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSentiment, setSelectedSentiment] = useState(null);
  const [selectedOutcomes, setSelectedOutcomes] = useState([]); // State for outcomes

  const categoryRefs = useRef({});
  const selectedFilters = useSelector(
    (state) => state.audit?.new_filters,
    shallowEqual
  );

  const { core_data } = useSelector(
    (state) => ({
      core_data: state.audit?.core_data,
      get_survey_structure: state.surveystructure.survey_structure,
    }),
    shallowEqual
  );

  const handleSelect = (item, parent) => {
    const selectedItem = {
      ...item,
      parent: parent?.id || parent?.name,
      id: item.id || item.key || item.value || item.name,
    };

    if (selectedItem.parent === "Sentiment") {
      if (selectedSentiment?.id === selectedItem.id) {
        setSelectedSentiment(null);
      } else {
        setSelectedSentiment(selectedItem);
      }
      onSentimentFilter(selectedItem.name);
    } else if (selectedItem.parent === "Outcomes") {
      const isSelected = selectedOutcomes.some(
        (outcome) => outcome.id === selectedItem.id
      );
      setSelectedOutcomes((prev) =>
        isSelected
          ? prev.filter((outcome) => outcome.id !== selectedItem.id)
          : [...prev, selectedItem]
      );

      onSelectOutcomes(selectedItem);
    } else {
      const isSelected = selectedFilters.some(
        (i) => i.id === selectedItem.id && i.parent === selectedItem.parent
      );
      let updatedFilters = isSelected
        ? selectedFilters.filter(
            (i) =>
              !(i.id === selectedItem.id && i.parent === selectedItem.parent)
          )
        : [...selectedFilters, selectedItem];

      dispatch({
        type: SET_NEW_FILTERS,
        payload: updatedFilters,
      });
    }
  };

  const clearAll = () => {
    setSelectedSentiment(null);
    setSelectedOutcomes([]);
    dispatch({ type: SET_NEW_FILTERS, payload: [] });
  };

  useEffect(() => {
    const refs = {};

    if (outcomes) {
      refs["Outcomes"] = React.createRef();
      outcomes.forEach((outcome) => {
        refs[outcome.id] = React.createRef();
      });
    }

    refs["Sentiment"] = React.createRef();
    refs["Action Orientation"] = React.createRef();
    if (core_data?.categories?.[0]?.categories) {
      core_data.categories[0].categories.forEach((category) => {
        refs[category.id] = React.createRef();
      });
    }

    categoryRefs.current = refs;
  }, [core_data?.categories, outcomes]);

  const handleCategoryClick = (categoryId) => {
    categoryRefs.current[categoryId]?.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <FilterComponent>
      <SelectedOptions>
        {selectedSentiment && (
          <SelectedOption onClick={() => setSelectedSentiment(null)}>
            {selectedSentiment.name} <IconImg src={CloseImg} />
          </SelectedOption>
        )}
        {selectedOutcomes.map((outcome, index) => (
          <SelectedOption
            key={index}
            onClick={() => handleSelect(outcome, { id: "Outcomes" })}
          >
            {outcome.name} <IconImg src={CloseImg} />
          </SelectedOption>
        ))}
        {selectedFilters.map((item, index) => (
          <SelectedOption
            key={index}
            onClick={() => handleSelect(item, { id: item.parent })}
          >
            {item.name} <IconImg src={CloseImg} />
          </SelectedOption>
        ))}
        {(selectedFilters.length > 0 || selectedOutcomes.length > 0) && (
          <Popup
            trigger={<Clear src={ClearImg} onClick={() => clearAll()} />}
            content="Clear All"
            inverted
            position="top center"
          />
        )}
      </SelectedOptions>

      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeIcon={false}
        trigger={
          <Container>
            <IconImg2 src={PlusImg} /> Add filters
          </Container>
        }
      >
        <Modal.Header>
          <HeaderContent>
            <ModalTitle>Filters</ModalTitle>
            <ClearButton onClick={() => setOpen(false)}>
              <IconImg src={CloseImg} />
            </ClearButton>
          </HeaderContent>
        </Modal.Header>
        <SearchBarContainer>
          <SearchBar
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SearchBarContainer>
        <Content>
          <SideMenuContainer>
            <SideMenu>
              <CategoryTitle onClick={() => handleCategoryClick("Outcomes")}>
                Outcomes
              </CategoryTitle>
              <CategoryTitle>Filter by feedback type</CategoryTitle>
              <Item onClick={() => handleCategoryClick("Sentiment")}>
                Sentiment
              </Item>
              <Item onClick={() => handleCategoryClick("Action Orientation")}>
                Action Orientation
              </Item>
              <CategoryTitle>Filter by category</CategoryTitle>
              {core_data?.categories?.[0]?.categories.map((subCategory) => (
                <Item
                  key={subCategory.id}
                  onClick={() => handleCategoryClick(subCategory.id)}
                >
                  {subCategory.name}
                </Item>
              ))}
            </SideMenu>
          </SideMenuContainer>

          <Separator />

          <MainMenuContainer>
            <MainMenu>
              <div ref={categoryRefs.current["Outcomes"]}>
                <Section>
                  <SectionTitle>Outcomes</SectionTitle>
                  <OutcomesSection>
                    {outcomes &&
                      outcomes
                        .filter((outcome) =>
                          outcome.name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((outcome) => (
                          <OutcomeOptions key={outcome.id}>
                            <Option
                              onClick={() =>
                                handleSelect(outcome, {
                                  id: "Outcomes",
                                  name: outcome.name,
                                })
                              }
                              selected={selectedOutcomes.some(
                                (item) => item.id === outcome.id
                              )}
                            >
                              {selectedOutcomes.some(
                                (item) => item.id === outcome.id
                              ) && <IconImg2 src={CheckImg} />}{" "}
                              {outcome.name}
                            </Option>
                          </OutcomeOptions>
                        ))}
                  </OutcomesSection>
                </Section>
              </div>

              <Section>
                <div ref={categoryRefs.current["Sentiment"]}>
                  <Title>Sentiment</Title>
                  <Options>
                    {sentiments
                      .filter((option) =>
                        option.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((option) => (
                        <Option
                          key={option.id}
                          onClick={() =>
                            handleSelect(option, { name: "Sentiment" })
                          }
                          selected={selectedSentiment?.name === option?.name}
                        >
                          {selectedSentiment?.name === option?.name && (
                            <IconImg2 src={CheckImg} />
                          )}{" "}
                          {option.name}
                        </Option>
                      ))}
                  </Options>
                </div>

                {core_data?.categories?.[0]?.categories.map((category) => (
                  <div
                    key={category.id}
                    ref={categoryRefs.current[category.id]}
                  >
                    <Title>{category.name}</Title>
                    <Options>
                      {category.options
                        ?.filter((option) =>
                          option.name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((option) => (
                          <Option
                            key={option.id}
                            onClick={() => handleSelect(option, category)}
                            selected={selectedFilters.some(
                              (item) =>
                                item.id === option.id &&
                                item.parent === category.id
                            )}
                          >
                            {selectedFilters.some(
                              (item) =>
                                item.id === option.id &&
                                item.parent === category.id
                            ) && <IconImg2 src={CheckImg} />}{" "}
                            {option.name}
                          </Option>
                        ))}
                    </Options>
                  </div>
                ))}
              </Section>
            </MainMenu>
          </MainMenuContainer>
        </Content>
        <Bottom>
          <Button onClick={() => setOpen(false)}>Select</Button>
        </Bottom>
      </Modal>
    </FilterComponent>
  );
};;;;;

export default Filters;

// Styled components

const Container = styled.div`
  color: #2d70e2;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  line-height: 150%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 0px;
`;

const SideMenuContainer = styled.div`
  width: 200px;
  overflow-y: auto;
  max-height: calc(100vh - 250px);

  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
  }
`;

const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const MainMenuContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 250px);

  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
  }
`;

const Item = styled.div`
  margin-bottom: 5px;
  height: 36px;
  padding: 4px 12px 4px 27px;
  color: #666d79;
  font-family: Raleway;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const MainMenu = styled.div`
  padding: 20px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const OutcomesSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const Options = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  font-family: Raleway;
  font-size: 14px;
  margin-bottom: 20px;
`;

const Option = styled.div`
  padding: 8px 16px;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 30px;
  font-family: Raleway;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: ${(props) => (props.selected ? "#EBF1FD" : "#fff")};
  color: ${(props) => (props.selected ? "#2D70E2" : "#2A3039")};
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;

const SelectedOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const SelectedOption = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  color: #2a3039;
  background-color: #f0f2f5;
  padding: 8px 16px;
  border-radius: 16px;
  cursor: pointer;
`;

const FilterComponent = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 85%;
`;

const IconImg = styled.img`
  width: 20px;
  margin-left: 3px;
`;

const IconImg2 = styled.img`
  width: 20px;
  margin-right: 4px;
  height: 20px;
`;

const Clear = styled.img`
  width: 20px;
  margin-left: 8px;
  cursor: pointer;
  margin-bottom: 0px;
  margin-right: 10px;
`;

const OutcomeOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Button = styled.button`
  background: #2d70e2;
  border-radius: 8px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  cursor: pointer;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  padding: 20px;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
`;

const ClearButton = styled.button`
  background: none;
  border: none;
  color: #2d70e2;
  font-size: 14px;
  cursor: pointer;
`;

const SearchBarContainer = styled.div`
  padding: 10px 20px;
  background-color: #fff;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  font-size: 14px;
`;

const Separator = styled.div`
  width: 1px;
  background-color: #e5e5e5;
  margin: 0 10px;
`;

const CategoryTitle = styled.div`
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Raleway;
  font-size: 14px;
  cursor: pointer; /* Made it clickable */
`;

const Section = styled.div`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
`;