// Libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dimmer, Loader, Button, Icon,Popup, Pagination} from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Sport from "./Sport";
import { Link } from "react-router-dom";
import format from "date-fns/format";

// Components
import PDF from "components/PDF";
import CreateReport from "./CreateReport";

// Actions
import {
  SG_GET_PDF_TEMPLATES,
  SG_CREATE_PDF_TEMPLATE,
  SG_GET_PDF_SAVED_COMMENTS,
  SG_CREATE_PDF_SAVED_COMMENTS,
  SG_UPDATE_PDF_SAVED_COMMENTS,
  SG_FIND_DEBRIEF_SCHEDULE,
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_CULTURE_AUDIT_REPORTS,
  SG_GET_EMPLOYEE_USER_CATEGORIES,
  SG_FETCH_DEBRIEF_SCHEDULE,
} from "constants/actions";
import { SET_DATA_ACNHOR } from "constants/actions";

import SurveyVersions from "../../../reports/Audit/VersionControl/index";

import Clear from "assets/images/UI/clear.svg";
import Download from "assets/images/UI/download.svg";
import DeleteModal from "./DeleteModal";
import { set } from "date-fns";
import { DownloadIcon } from "reports/Dashboard/Icons";

const OtpPDFReport = () => {
  /********* Declarations **********/
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [pdfTemplate, setPdfTemplate] = useState(false);
  const [selectOptions, setSelectOptions] = useState();
  const [startDate, setStartDate] = useState(new Date("2022/02/08"));
  const [endDate, setEndDate] = useState(new Date());
  const [options, setOptions] = useState({
    startDate: startDate,
    endDate: endDate,
    sports: [],
  });
  const [category_access, setAccess] = useState([]);
  const [selected, setSelected] = useState();
  const [comments, setComments] = useState();
  const [primary, setPrimary] = useState();
  const [sportOptions, setSportOptions] = useState();
  const [sportName, setSportName] = useState();
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [debriefSchedule, setDebriefSchedule] = useState();
  const [pdfComments, setPdfComments] = useState();
  const [pdfId, setPdfId] = useState();
  const [createEnabled, setCreateEnabled] = useState(false);
  const [isFrench, setIsFrench] = useState(false);
  const [customTitle, setCustomTitle] = useState();
  const [generateReport, setGenerateReport] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [sortOrder, setSortOrder] = useState();
  const [surveyVersions, setSurveyVersions] = useState([]);
  const [selectedSurveyVersion, setSelectedSurveyVersion] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [canEdit, setCanEdit] = useState();
  const [canAccessData, setCanAccessData] = useState();
  const [filteredCategories, setFilteredCategories] = useState();
  const [employeeCategories, setEmployeeCategories] = useState();
  const [empCategoryList, setEmpCategoryList] = useState();
  const [otpDualView, setOtpDualView] = useState();
  const [otpView, setOtpView] = useState();
  const [open, setOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reportId, setReportId] = useState();
  const [completionRate, setCompletionRate] = useState();
  const [surveyDate, setSurveyDate] = useState();
  const [hasDebriefError, setHasDebriefError] = useState(false);
  const [report, setReport] = useState();
  useEffect(() => {
    // Initial state of pending is false which doesn't allow for proper
    // loading state check.
    setTimeout(() => {
      setLoadingData(true);
    }, 200);
  }, []);

  const handleBtnPress = (id) => {
    setCompletionRate(null);
    setSurveyDate(null);
    setGenerateReport(null);
    if (id) {
      const pdfComment = pdfComments.find((comment) => {
        return comment?.details?.pdfId === id;
      });

      if (pdfComment) {
        setReportId(pdfComment?.debrief_schedule);
      }
    }

    setGenerateReport(id);
  };

  useEffect(() => {
    if (reportId) {
      dispatch({
        type: SG_FETCH_DEBRIEF_SCHEDULE,
        payload: {
          id: reportId,
        },
      });
    }
  }, [reportId, generateReport]);

  const handlePaginationChange = (e, { activePage }) => {
    setPage(activePage);
  };

  /********* Selectors **********/
  const pdfConfig = useSelector(
    (state) => state.pdfGenerator.pdfConfig,
    shallowEqual
  );

  const { pdfComments: commentsResponse } = useSelector(
    (state) => ({
      pdfComments: state.PDFComments.pdfComments,
    }),
    shallowEqual
  );

  const {
    get_auth,
    get_debrief_schedules,
    get_culture_audit_reports,
    reportsPending,
    survey_version,
    get_survey_structure,
    get_employee,
    get_employee_categories,
    get_survey_questions,
    get_employee_user_category,
    error,
  } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_debrief_schedules: state.debrief_schedule,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      reportsPending: state.debrief_schedule.reportsPending,
      survey_version: state.audit.survey_version,
      get_survey_structure: state.surveystructure.survey_structure,
      get_employee: state.employees,
      get_employee_categories:
        state.employees.userEmp.employee_employee_category,
      get_survey_questions: state.surveyquestions.survey_questions,
      get_employee_user_category:
        state.employee_category.employee_user_category,
      error: state.errors,
    }),
    shallowEqual
  );

  useEffect(() => {
    let completionRate;
    let surveyDate;
    if (report?.details?.pdfId) {
      const pdfComment = pdfComments.find((comment) => {
        return comment?.details?.pdfId === report?.details?.pdfId;
      });

      if (pdfComment) {
        if (get_debrief_schedules?.[pdfComment?.debrief_schedule]) {
          completionRate = {
            totalResponses:
              get_debrief_schedules?.[pdfComment?.debrief_schedule]
                ?.total_responses,
            totalRequests:
              get_debrief_schedules?.[pdfComment?.debrief_schedule]
                ?.total_requests,
          };

          surveyDate =
            get_debrief_schedules?.[pdfComment?.debrief_schedule]
              ?.scheduled_for;
        }
      }
    }
    if (completionRate && generateReport) {
      // Object to be sent to the new window
      const transferObject = {
        coverSubtitle: "PDF summary report",
        coverTitle: report?.details?.coverTitle,
        anchor: selected,
        config: pdfConfig,
        sortOrder: report?.details?.sortOrder || survey_version?.sort_order,
        options: report?.details?.options,
        overview: true,
        reportComments: report?.details?.comments,
        otpFormat: true,
        downloadIcon: true,
        isOTP: true,
        selectedSport: report?.details?.selectedSport,
        sportName: report?.details?.sportName,
        aiData: report?.details?.gptResponse,
        buttonEnabled: true,
        otpVersion: false,
        isFrench: report?.details?.isFrench,
        auditReports: get_culture_audit_reports,
        completionRate: completionRate,
        surveyDate: surveyDate,
        pdfKey: report?.details?.pdfId,
        survey_version: survey_version,
        structure: get_survey_structure[0],
      };
      // Open a new window
      const newTab = window.open(
        `${window.location.origin}/app/otp/generate-pdf`,
        "_blank"
      );

      // Create a broadcast channel
      const bc = new BroadcastChannel("pdf_channel");

      // Once the window is open it will send a message to us to let us know it is ready.
      bc.onmessage = (ev) => {
        if (ev?.data?.windowOpen) {
          bc.postMessage(transferObject);
        }
        // bc.postMessage(transferObject);
      };
      setGenerateReport(null);
    }
  }, [generateReport, get_debrief_schedules]);

  // useEffect(() => {
  //   if (get_debrief_schedules?.[reportId]) {
  //     setCompletionRate({
  //       totalResponses: get_debrief_schedules?.[reportId]?.total_responses,
  //       totalRequests: get_debrief_schedules?.[reportId]?.total_requests,
  //     });

  //     setSurveyDate(get_debrief_schedules?.[reportId]?.scheduled_for);
  //   }
  // }, [get_debrief_schedules, reportId]);

  // Check for errors that might prevent pdf from generating
  useEffect(() => {
    if (error?.data?.detail) {
      setHasDebriefError(true);
    }
  }, [error]);

  useEffect(() => {
    setIsAdmin(false);
    setCanEdit(false);
    setCanAccessData(false);
    if (get_employee) {
      // setUserEmployee(get_employees?.userEmp);
      setCurrentUser(get_employee?.userEmp);
      // check for admin
      if (get_employee.userEmp?.account_type === 5) {
        setIsAdmin(true);
      }

      if (get_employee.userEmp?.survey_add_users === 1) {
        setCanEdit(true);
      }

      if (get_employee.userEmp?.access_data_analytics === 1) {
        setCanAccessData(true);
      }
    }
  }, [get_employee]);

  useEffect(() => {
    if (get_auth?.employee_id) {
      if (get_employee_user_category?.length > 0) {
        const currentCategories = get_employee_user_category.find(
          (f) => f.employee_id === Number(get_auth?.employee_id)
        );
        setEmployeeCategories(currentCategories);
      }
    }
  }, [get_auth?.employee_id, get_employee_user_category]);

  useEffect(() => {
    if ((canEdit || canAccessData) && !filteredCategories) {
      if (isAdmin) {
        const filterCat = get_survey_structure?.[0]?.categories.filter(
          (c) => c.priority === "primary"
        )?.[0]?.options;

        setFilteredCategories(filterCat);

        return;
      }

      if (employeeCategories?.category?.length > 0) {
        const filterCat = employeeCategories?.category
          .filter((c) => {
            return c?.priority === "primary";
          })
          .map((c) => {
            return c.value_id;
          });

        setFilteredCategories(filterCat);

        return;
      }
    }
  }, [
    currentUser,
    employeeCategories,
    canEdit,
    canAccessData,
    get_survey_structure,
  ]);

  useEffect(() => {
    if (!reportsPending) {
      setLoadingData(false);
    }
  }, [reportsPending]);

  useEffect(() => {
    if (!get_survey_structure[0]) {
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${Number(get_auth.organization_id)}`,
      });
    }
  }, [get_auth]);

  useEffect(() => {
    if (get_auth && get_employee_categories?.categories?.length === 0) {
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `employee=${get_auth?.employee_id}`,
      });
    }
    dispatch({
      type: SG_GET_EMPLOYEE_USER_CATEGORIES,
      payload: `employee=${get_auth?.employee_id}`,
    });
  }, [get_auth]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `survey_type=8&status=2`,
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: SG_GET_CULTURE_AUDIT_REPORTS,
      payload: `audit-type-id=3`,
    });
  }, []);

  useEffect(() => {
    if (get_survey_questions && get_survey_questions.length > 0) {
      const surveyVersions = get_survey_questions.map((survey) => {
        return survey.name;
      });
      setSurveyVersions(surveyVersions);
      setSortOrder(get_survey_questions[0]?.sort_order);
    }
  }, [get_survey_questions]);

  useEffect(() => {
    if (get_auth?.organization_id) {
      dispatch({
        type: SG_GET_CULTURE_AUDIT_REPORTS,
        payload: `audit-type-id=5&organization=${get_auth?.organization_id}`,
      });
    }
  }, [get_auth?.organization_id]);

  // If isOtp, check to see if account is OTP view.
  useEffect(() => {
    if (
      employeeCategories &&
      employeeCategories?.category &&
      get_survey_structure.length > 0
    ) {
      let otpView = true;
      const primaryList = get_survey_structure?.[0]?.categories.filter(
        (c) => c.priority === "primary"
      )?.[0]?.options;

      const empCategories = employeeCategories?.category.map((ci) => ci?.value);

      setEmpCategoryList(empCategories);

      primaryList.forEach((li) => {
        if (!empCategories.includes(li.name)) {
          otpView = false;
        }
      });

      if (otpView === true && empCategories.length > primaryList.length) {
        setOtpDualView(true);
      }
      setOtpView(otpView);
    }
  }, [employeeCategories, get_survey_structure]);

  useEffect(() => {
    if (otpView && !otpDualView) {
      setButtonEnabled(true);
    }

    if (otpDualView && sportOptions) {
      setButtonEnabled(true);
    }

    if (!otpView && sportOptions) {
      setButtonEnabled(true);
    }
  }, [sportOptions, otpView, otpDualView]);

  useEffect(() => {
    if (get_employee_categories?.length > 0) {
      setAccess(
        get_employee_categories.find(
          (f) => f.employee === Number(get_auth.employee_id)
        )?.category
      );
    }
  }, [get_auth, get_employee_categories]);

  // Check if selected sport has data, enable/disable button
  useEffect(() => {
    if (sportName && survey_version?.sort_order) {
      const sportData = get_culture_audit_reports?.response
        ?.filter((survey) => {
          return survey?.question_sort_order === survey_version?.sort_order;
        })
        ?.find((f) => {
          return f.name === sportName || primary === f.name_uid;
        });
      if (sportData?.responses?.length > 0) {
        setCreateEnabled(true);
      } else {
        setCreateEnabled(false);
      }
    }
  }, [
    sportName,
    get_culture_audit_reports,
    survey_version?.sort_order,
    primary,
  ]);

  useEffect(() => {
    const anchor = get_survey_structure?.[0]?.categories.find(
      (f) => f.priority === "secondary"
    );
    if (anchor) {
      setSelected(anchor?.id);
    }
  }, [get_survey_structure]);

  useEffect(() => {
    if (!pdfId) {
      // create unique id based on timestamp
      setPdfId(new Date().getTime());
    }
  }, [pdfId]);

  useEffect(() => {
    if (sportName) {
      dispatch({
        type: SG_FIND_DEBRIEF_SCHEDULE,
        payload: `type_of=5&organization=${get_auth?.organization_id}&name=${sportName}&curr-status=history
      `,
      });
    }
  }, [sportName]);

  useEffect(() => {
    if (commentsResponse?.length > 0) {
      const commentsFiltered = commentsResponse
        .filter((f) => f?.details?.display)
        .sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

      setTotalPages(Math.ceil(commentsFiltered.length / 10));
      // paginate the commentsFiltered based on the page.
      const start = (page - 1) * 10;
      const end = start + 10;
      const commentsPaginated = commentsFiltered.slice(start, end);

      setPdfComments(commentsPaginated);
    }
  }, [commentsResponse, get_auth, page]);

  useEffect(() => {
    if (
      get_debrief_schedules?.latest_schedule?.results?.length > 0 &&
      sportName
    ) {
      const filteredSchedules =
        get_debrief_schedules?.latest_schedule?.results?.filter(
          (debriefSchedule) => {
            const scheduledStart = new Date(debriefSchedule.scheduled_for);
            const scheduledEnd = new Date(debriefSchedule.close_at);
            const cleanName = debriefSchedule.name.replace(/[ \t]+(\r?$)/, "");
            return (
              scheduledStart >= startDate &&
              scheduledEnd <= endDate &&
              cleanName === sportName
            );
          }
        );

      if (filteredSchedules?.length > 0) {
        setDebriefSchedule(filteredSchedules[0]?.id);
      }
    }
  }, [get_debrief_schedules, startDate, endDate, sportName]);

  /********* Functions & Routines **********/
  const resetDefaultsBtnClicked = () => {
    setStartDate(new Date("2022/02/08"));
    setEndDate(new Date());
    setOptions({
      startDate: new Date("2022/02/08"),
      endDate: new Date(),
      sports: [],
    });
  };

  const createPDFSavedState = (state) => {
    setOpen(false);
    if (debriefSchedule) {
      dispatch({
        type: SG_CREATE_PDF_SAVED_COMMENTS,
        payload: {
          details: {
            ...state,
            comments: [],
            employeeId: get_auth?.employee_id,
            display: true,
          },
          debrief_schedule: debriefSchedule,
        },
      });
    }
  };

  const handleDeletePDFSavedState = (report) => {
    dispatch({
      type: SG_UPDATE_PDF_SAVED_COMMENTS,
      payload: {
        ...report,
        details: {
          ...report.details,
          display: false,
        },
      },
    });

    const currentReports = [...pdfComments];
    const newReports = currentReports.filter((f) => f?.id !== report?.id);
    setPdfComments(newReports);
    setConfirmDelete(false);
  };

  /********* Dispatch Calls **********/
  // Retrieve PDF Templates (if they exist).
  useEffect(() => {
    dispatch({
      type: SG_GET_PDF_TEMPLATES,
    });
  }, [dispatch]);

  // Create a PDF Template (if one does not exist).
  useEffect(() => {
    if (pdfTemplate.length === 0) {
      dispatch({
        type: SG_CREATE_PDF_TEMPLATE,
        payload: { name: "ver 1.0" },
      });
    }
  }, [pdfTemplate, dispatch]);

  useEffect(() => {
    dispatch({
      type: SET_DATA_ACNHOR,
      payload: selected,
    });
  }, [dispatch, selected]);

  useEffect(() => {
    dispatch({
      type: SG_GET_PDF_SAVED_COMMENTS,
    });
  }, [dispatch]);

  useEffect(() => {
    const sportsSelected = sportName ? sportName : undefined;
    setOptions({
      ...options, // keep all other key-value pairs
      sports: [sportsSelected], // update the value of specific key
    });
  }, [sportName]);

  useEffect(() => {
    setPdfTemplate(pdfConfig);
  }, [pdfConfig]);

  // Create array of select options based on primary categories
  useEffect(() => {
    if (!selectOptions && get_survey_structure?.[0]) {
      const options = get_survey_structure?.[0]?.categories
        .find(
          (category) => category.name.toLowerCase() === "sport".toLowerCase()
        )
        ?.options.map((catOption) => {
          return {
            key: catOption.name,
            text: catOption.name,
            value: catOption.name,
          };
        });
      setSelectOptions(options);
      setLoading(false);
    }
  }, [get_survey_structure, selectOptions]);

  useEffect(() => {
    if (primary && sportOptions) {
      const sportName = sportOptions
        .find((so) => so.value === primary)
        .text.replace(/[ \t]+(\r?$)/, "");
      setSportName(sportName);
    }
  }, [primary, sportOptions]);

  /********* Render Functions **********/
  const SelectDate = React.forwardRef((props, ref) => (
    <SelectContainer
      ref={ref}
      onClick={props.onClick}
      style={{ cursor: "pointer" }}
    >
      <Icon
        name="calendar alternate"
        size={"small"}
        style={{ color: "#6a00ff", marginRight: 8 }}
      />{" "}
      {props.value}
    </SelectContainer>
  ));

  if (
    loading ||
    !pdfConfig[0] ||
    !get_employee ||
    !selected ||
    !empCategoryList
  ) {
    return (
      <LoadingWrapper>
        <Dimmer active={loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      </LoadingWrapper>
    );
  }

  const sportSelect = (
    <Sport
      categories={get_survey_structure[0]}
      selected={primary}
      setSelected={(d) => {
        setPrimary(d);
      }}
      setSportOptions={(d) => setSportOptions(d)}
      isAdmin={isAdmin}
      otpDualView={otpDualView}
      empCategoryList={empCategoryList}
      filteredCategoriesProp={filteredCategories}
    />
  );

  const ToggleComponent = (props) => {
    const handleChange = () => {
      props.handleCheck(!props.checked);
    };

    return (
      <ToggleLabel disabled={props.disabled}>
        <ToggleInput
          type="checkbox"
          onChange={() => !props.disabled && handleChange()}
          value={props.checked}
          checked={props.checked}
        />
        <ToggleSlider isChecked={props.checked} />
      </ToggleLabel>
    );
  };

  const generatePDF = (report) => {
    setCompletionRate(null);
    setSurveyDate(null);
    if (report?.details?.pdfId) {
      const pdfComment = pdfComments.find((comment) => {
        return comment?.details?.pdfId === report?.details?.pdfId;
      });

      if (pdfComment) {
        setReportId(pdfComment?.debrief_schedule);
      }
    }

    setGenerateReport(report?.details?.pdfId);
    setReport(report);
  };

  const generate_button = (
    <GenerateButtonWrapper>
      {(loadingData && (
        <Loader inline active size="tiny" style={{ marginRight: 10 }} />
      )) ||
        (!loadingData &&
          get_survey_structure?.[0] &&
          pdfConfig?.length > 0 &&
          options &&
          pdfId && (
            <PDFGenerateWrapper>
              {primary && createEnabled ? (
                <Link
                  onClick={() =>
                    createPDFSavedState({
                      options,
                      overview: true,
                      anchor: selected,
                      config: pdfConfig,
                      selectedSport: primary,
                      sortOrder: survey_version?.sort_order,
                      sportName,
                      otpVersion: false,
                      coverTitle: customTitle,
                      pdfId,
                      isFrench,
                    })
                  }
                  state={{
                    options,
                    overview: true,
                    anchor: selected,
                    config: pdfConfig,
                    selectedSport: primary,
                    sportName,
                    otpVersion: false,
                    id: pdfId,
                    coverTitle: customTitle,
                    sortOrder: survey_version?.sort_order,
                    isFrench,
                  }}
                  to={{
                    pathname: "/app/otp/pdf2",
                    search: `?id=${pdfId}`,
                  }}
                >
                  <Button disabled={!primary} color="blue">
                    Create Report
                  </Button>
                </Link>
              ) : (
                <Button disabled={true}>Create Report</Button>
              )}
            </PDFGenerateWrapper>
          ))}
    </GenerateButtonWrapper>
  );

  const reportResume = (
    <ReportResume>
      <ReportsBox>
        <ReportRow>
          <ReportResumeItemCover>
            <ColTitleText>Title</ColTitleText>
          </ReportResumeItemCover>
          <ReportResumeItemTitle>
            <ColTitleText>Sport</ColTitleText>
          </ReportResumeItemTitle>
          {/* <ReportResumeItemDate>
            <ColTitleText style={{ paddingLeft: 10 }}>
              Report Start
            </ColTitleText>
          </ReportResumeItemDate> */}
          <ReportResumeEndDate>
            <ColTitleText>Date</ColTitleText>
          </ReportResumeEndDate>
          <LanguageCol>
            <ColTitleText>Language</ColTitleText>
          </LanguageCol>
          <ColTitle>
            <ColTitleText>Actions</ColTitleText>
          </ColTitle>
        </ReportRow>
        <ReportsList>
          {pdfComments?.length > 0 &&
            pdfComments
              .sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
              })
              .map((report) => {
                return (
                  <ReportItemRow>
                    <ReportResumeItemCover>
                      {report?.details?.coverTitle || "Culture Audit"}
                    </ReportResumeItemCover>
                    <ReportResumeItemTitle>
                      {report?.details?.sportName}
                    </ReportResumeItemTitle>
                    {/* <ReportResumeItemDate>
                      {format(
                        new Date(report?.details?.options?.startDate),
                        "EEEE/MMM/do"
                      )}
                    </ReportResumeItemDate> */}
                    <ReportResumeEndDate>
                      {format(
                        new Date(report?.details?.options?.endDate),
                        "MMM do, yyyy"
                      )}
                    </ReportResumeEndDate>
                    <LanguageCol>
                      {report?.details?.isFrench ? "FR" : "EN"}
                    </LanguageCol>
                    <ReportActions>
                      <Popup
                        content="Delete"
                        position="top center"
                        inverted
                        trigger={
                          <IconImg
                            src={Clear}
                            onClick={() => setConfirmDelete(report)}
                          />
                        }
                      />

                      <PDFButtonWrapper>
                        {(survey_version &&
                          generateReport !== report?.details?.pdfId && (
                            <Popup
                              content="Download"
                              position="top center"
                              inverted
                              trigger={
                                <IconImg
                                  src={Download}
                                  onClick={() => generatePDF(report)}
                                />
                              }
                            />
                          )) || (
                          <Loader
                            inline
                            active
                            size="tiny"
                            style={{ marginRight: 10 }}
                          />
                        )}
                      </PDFButtonWrapper>
                      <Link
                        state={{
                          options: {
                            ...report?.details?.options,
                            startDate: new Date(
                              report?.details?.options?.startDate
                            ),
                            endDate: new Date(
                              report?.details?.options?.endDate
                            ),
                          },
                          overview: true,
                          anchor: selected,
                          config: pdfConfig,
                          sortOrder: report?.details?.sortOrder || sortOrder,
                          selectedSport: report?.details?.selectedSport,
                          sportName: report?.details?.sportName,
                          otpVersion: otpView && !otpDualView,
                          coverTitle: report?.details?.coverTitle,
                          id: report?.details?.pdfId,
                          isFrench: report?.details?.isFrench,
                        }}
                        to={{
                          pathname: "/app/otp/pdf2",
                          search: `?id=${report?.details?.pdfId}`,
                        }}
                      >
                        <Popup
                          content="Edit"
                          position="top center"
                          inverted
                          trigger={
                            <Button icon="right arrow" size="tiny"></Button>
                          }
                        />
                      </Link>
                    </ReportActions>
                  </ReportItemRow>
                );
              })}
        </ReportsList>
      </ReportsBox>

      <Pagination
        activePage={page}
        onPageChange={handlePaginationChange}
        boundaryRange={1}
        size="mini"
        firstItem={null}
        lastItem={null}
        siblingRange={0}
        totalPages={totalPages}
      />
    </ReportResume>
  );

  return (
    <Page>
      <SurveyVersions hidden={true} />
      <PageHeader>
        Reports
        {get_culture_audit_reports && (
          <CreateReport
            open={open}
            setOpen={() => setOpen(!open)}
            create_button={generate_button}
            customTitle={customTitle}
            setCustomTitle={setCustomTitle}
            get_culture_audit_reports={get_culture_audit_reports}
            isFrench={isFrench}
            setIsFrench={setIsFrench}
            ToggleComponent={ToggleComponent}
            sportSelect={sportSelect}
          />
        )}
      </PageHeader>
      {/* <OptionsSection>
        {(!otpView || otpDualView || isAdmin) && sportSelect}

        <Title>Language</Title>
        <Description>Please select the language you would like to use in this report.</Description>
        <ToggleContainer>
          <ToggleComponent
            toggle
            checked={isFrench}
            handleCheck={(s) => setIsFrench(s)}
          />           
          <ToggleText disabled={false}>Generate French Version</ToggleText>
        </ToggleContainer>
        <CustomTitleContainer>
          <Title>Title</Title>
          <Description>Give this report a title so that you can differentiate the report from others.</Description>
          <CustomTitleInput
            value={customTitle}
            onChange={(e) => setCustomTitle(e.target.value)}
          />
        </CustomTitleContainer>
        <DropdownWrapper>
          <Title>Survey Version</Title>
          <Description>Select a version of the CAAT survey to use for your report. </Description>
          {get_culture_audit_reports && <SurveyVersions />}
        </DropdownWrapper>
      </OptionsSection>
      <ButtonsSection>
        <div style={{ display: "flex" }}>
          <ResetButtonWrapper>
            <Button onClick={() => resetDefaultsBtnClicked()}>Reset</Button>
          </ResetButtonWrapper>
          <GenerateButtonWrapper>
            {(loadingData && (
              <div style={{ marginLeft: 15, marginRight: 10 }}>
                <Loader inline active size="small" />
              </div>
            )) ||
              (!loadingData &&
                get_survey_structure?.[0] &&
                pdfConfig?.length > 0 &&
                options &&
                pdfId && (
                  <PDFGenerateWrapper>
                    {primary && createEnabled ? (
                      <Link
                        onClick={() =>
                          createPDFSavedState({
                            options,
                            overview: true,
                            anchor: selected,
                            config: pdfConfig,
                            selectedSport: primary,
                            sortOrder: survey_version?.sort_order,
                            sportName,
                            otpVersion: false,
                            coverTitle: customTitle,
                            pdfId,
                            isFrench,
                          })
                        }
                        state={{
                          options,
                          overview: true,
                          anchor: selected,
                          config: pdfConfig,
                          selectedSport: primary,
                          sportName,
                          otpVersion: false,
                          id: pdfId,
                          coverTitle: customTitle,
                          sortOrder: survey_version?.sort_order,
                          isFrench,
                        }}
                        to={{
                          pathname: "/app/otp/pdf2",
                          search: `?id=${pdfId}`,
                        }}
                      >
                        <Button disabled={!primary} color="blue">
                          Create Report
                        </Button>
                      </Link>
                    ) : (
                      <Button disabled={true}>Create Report</Button>
                    )}
                  </PDFGenerateWrapper>
                ))}
          </GenerateButtonWrapper>
        </div> */}
      {/* {get_survey_structure?.[0] &&
          pdfConfig?.length > 0 &&
          options &&
          (otpDualView || otpView || isAdmin) && (
            <GenerateOTPButtonWrapper>
              {get_survey_structure?.[0] && pdfConfig?.length > 0 && options && (
                <div style={{ marginTop: 30 }}>
                  <Title>Generate an OTP aggregate report</Title>
                  {(loadingData && (
                    <div style={{ marginLeft: 15, marginRight: 10 }}>
                      <Loader inline active size="small" />
                    </div>
                  )) || (
                    <PDF
                      coverSubtitle={"PDF summary report"}
                      coverTitle={customTitle}
                      anchor={selected}
                      config={pdfConfig}
                      options={options}
                      overview={true}
                      comments={comments}
                      otpFormat={true}
                      isOTP={true}
                      // downloadIcon={true}
                      // selectedSport={primary}
                      // sportName={sportName}
                      buttonLabel="Create OTP Report"
                      buttonEnabled={true}
                      otpVersion={true}
                      otpView={true}
                      reportButton={true}
                      isFrench={isFrench}
                    />
                  )}
                </div>
              )}
            </GenerateOTPButtonWrapper>
          )} */}
      {/* </ButtonsSection> */}
      {(isAdmin || !otpView || otpDualView) &&
        pdfComments?.length > 0 &&
        reportResume}

      <DeleteModal
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleDeletePDFSavedState={handleDeletePDFSavedState}
      />
    </Page>
  );
};

/********** Styles ***********************/
const LoadingWrapper = styled.div`
  padding: 300px;
`;

const PDFGenerateWrapper = styled.div`
  width: 150px;
`;

const PDFButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0px;
`;

const SelectContainer = styled.div`
  width: 150px;
  height: 30px;
  border-radius: 8px;
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #4a4a4a;
  position: relative;
  z-index: 1;
  margin-right: ${(props) => (props.margin ? props.margin : 0)};
  margin-right: 10px;

  @media (max-width: 768px) {
    padding-left: 10px;
    width: 150px;
    font-size: 13px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 5px;
  margin-top: 20px;
`;

const ButtonsSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 40px;
`;

const ResetButtonWrapper = styled.div`
  padding-right: 15px;
`;

const DownloadBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const GenerateButtonWrapper = styled.div`
  display: flex;
  text-align: center;
  line-height: 35px;
`;

const GenerateOTPButtonWrapper = styled.div`
  display: flex;
  text-align: center;
  line-height: 35px;
  margin-top: 20px;
`;

const Page = styled.div`
  padding: 10px 0px;
  padding-left: 58px;
  font-familly:"Raleway";

  @media (max-width: 600px) {
    display: none;
  }
`;

const PageHeader = styled.div`
  padding: 35px 0px;
  font-size: 32px;
  font-weight: 700;
  font-family: "Raleway";
  color:#2A3039;
  display:flex;
  width:100%;
  justify-content:space-between;
  align-items:center;
  padding-right:30px;
`;

const PageSubtitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 60px;
`;

const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 35px;
`;

const SelectionSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 35px;
  line-height: 35px;
`;

const SelectorLabel = styled.div`
  font-size: 18px;
  font-weight: 400;
  padding-right: 8px;
`;

const DateRangeWrapper = styled.div`
  z-index: 99;
`;

const DateRangeToLabel = styled.div`
  padding-right: 8px;
`;

const ReportResume = styled.div`
  max-width: 1400px;

  margin-top: 20px;
  `;

const ReportResumeTitle = styled.div`

  font-size: 18px;
  font-weight: bold;
  color: #4a4a4a;
  padding-right: 8px;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const ReportResumeItemCover = styled.div`
  width: 25%;
  display: flex;
  justify-content: flex-start;
`;

const ReportResumeItemTitle = styled.div`
  width: 25%;
  display: flex;
  justify-content: flex-start;
`;

const ReportResumeItemDate = styled.div`
  width: 166px;
  display: flex;
  left: 10px;
  justify-content: flex-start;
`;

const ReportResumeEndDate = styled.div`
  width: 15%;
  display: flex;
  paddingleft: 10px;
  justify-content: flex-start;
`;

const ReportsList = styled.div`

  width:100%;
`;

const ReportsBox = styled.div`
  margin-bottom:20px;
`;

const ReportRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;

const ReportItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background-color: #f8f8f8;
  font-family: "Raleway";
`;

const ColTitle = styled.div`
  width: 20%;
`;

const ColTitleText = styled.div`
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 600;
color:#666D79;
`;

const ReportActions = styled.div`
  width:20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToggleLabel = styled.label`
 display: flex;
 align-items: center;
 gap: 10px;
 cursor: ${(props) => (props.disabled ? "default" : "pointer")};
 opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const ToggleText = styled.div`
 font-size: 14px;
 font-family: "Raleway";
 font-weight: 400;
 color: #2A3039;
 display: flex;
 align-items: center;
 padding-left: 10px;
`;

const ToggleSlider = styled.div`
 position: relative;
 width: 40px;
 height: 16px;
 background: #e7e7e7;
 border-radius: 26.6667px;
 padding: 4px;
 transition: 300ms all;

 &:before {
  transition: 300ms all;
  content: "";
  position: absolute;
  width: 15px;
  height: 16px;
  border-radius: 35px;
  top: 50%;
  left: 4px;
  background: ${(props) => (props.isChecked ? "#66d686" : "#c0c0c0")};
  transform: translate(0, -50%);
 }
`;

const ToggleInput = styled.input`
 display: none;

 &:checked + ${ToggleSlider} {
  background: #e7e7e7;

  &:before {
   transform: translate(20px, -50%);
  }
 }
`;

const ToggleContainer = styled.div`
 display: flex;
 flex-direction: row;
 height: 35px;
 vertical-align: middle;
 padding-top: 0px;
`;

const LanguageCol = styled.div`
 width: 15%;
 display: flex;
 justify-content: flex-start;
 align-items: center;
`;

const CustomTitleContainer = styled.div`
 padding-bottom: 15px;
`;

const CustomTitleInput = styled.input`
 width: 400px;
 height: 35px;
 border: 1px solid #ccc;
 padding: 0px 10px;
 font-size: 14px;
 border-radius:5px;
 font-weight: 500;
 color: #4a4a4a;
`;

const Description = styled.div`
font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 400;
color:#2A3039;
padding-bottom: 10px;

`

const IconImg = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
  cursor:pointer;
`;


export default OtpPDFReport;